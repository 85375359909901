<div class="row col-lg-12">

    <div class="col-lg-2 wrapper-top-content">
      <div>
        <br />
        <h4 class="">Configurações</h4>
        <hr />
        <a *ngFor="let menu of _menu_nav_bar" class="dropdown-item"
        [routerLink]="[menu.route]" >{{menu.text}}</a>
      </div>
    </div>
    <div class="col-lg-10">  
      <router-outlet></router-outlet>  
    </div>
  </div>