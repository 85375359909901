import { Component, OnInit, Input, ɵConsole, Output, EventEmitter } from '@angular/core';
import { ProdutoComprador } from 'src/app/domain/suprimento/comprador/produto-comprador';
import { EntregaParcialComprador } from 'src/app/domain/suprimento/comprador/entrega-parcial-comprador';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { ProdutoCompradorService } from 'src/app/service/suprimento/comprador/produto-comprador.service';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
declare var $: any;
@Component({
  selector: 'app-entrega-parcial-comprador',
  templateUrl: './entrega-parcial-comprador.component.html',
  styleUrls: ['./entrega-parcial-comprador.component.css']
})
export class EntregaParcialCompradorComponent implements OnInit {

  @Input() _produto_selecionado;

  @Output() _entregas_emit: EventEmitter<Array<EntregaParcialComprador>> = new EventEmitter();

  _params_truck = new ParamsTruck();

  public _entregas_parcial: Array<EntregaParcialComprador>;
  public _entrega_parcial: EntregaParcialComprador;

  public _restante: number;
  public _adicionado: number;
  public _total: string;
  public _msg: string;
  model: NgbDateStruct;

  constructor(private calendar: NgbCalendar,
    private produtoCompradorService: ProdutoCompradorService) {
    this._entregas_parcial = new Array<EntregaParcialComprador>();
    this._entrega_parcial = new EntregaParcialComprador();
  }

  ngOnInit() {
    this._adicionado = 0;
    this.Init();
  }

  Init() {
    var _this = this;
    $('#data-entraga-parcial-modal').on('show.bs.modal', function (e) {
      _this._on_save = false;
      _this._entregas_parcial = new Array<EntregaParcialComprador>();
      _this._params_truck = new ParamsTruck();
      setTimeout(() => {
        _this.CarregarDados();
      }, 10);
    });
  }

  CarregarDados() {
    this.produtoCompradorService.GetAllEntregaPacial(this._produto_selecionado.id).subscribe(
      d => {
        if (d && d.length > 0)
          this._entregas_parcial = d;
      },
      e => { },
      () => {
        if (this._entregas_parcial && this._entregas_parcial.length > 0) {
          var x = 1;
          this._entregas_parcial.forEach(c => {
            c._quantidade = c.quantidade.toFixed(2).replace('.', ',');
            c._data_entrega = UseFullService.formatDate(new Date(c.data_entrega));
            c._index = x++;
            this._params_truck.adicionado += c.quantidade;
          });

          this._params_truck.restante = this._produto_selecionado.quantidade - this._params_truck.adicionado;
          this._params_truck._restante = this._params_truck.restante.toFixed(2).replace('.', ',');
          this._params_truck._adicionado = this._params_truck.adicionado.toFixed(2).replace('.', ',');
        }
        this._params_truck._total = this._produto_selecionado.quantidade.toFixed(2).replace('.', ',');
      }
    );
  }

  selectToday() {
    this.model = this.calendar.getToday();
    this.ChangeDate();
  }

  ChangeDate() {
    setTimeout(() => {
      if (!this.model) {
        this._msg = "Selecione uma data valida.";
      } else {
        $('.date-limit').val(UseFullService.GetFormatDate(this.model.day, this.model.month, this.model.year));
        this._entrega_parcial._data_entrega = $('.date-limit').val();
        this._entrega_parcial.data_entrega = $('.date-limit').val();
      }
    }, 10);
  }

  Adicionar(entrega: EntregaParcialComprador) {

    setTimeout(() => { this._msg = ""; }, 20000);
    this.ChangeDate();
    if (entrega.quantidade <= 0) {
      this._msg = "A quantidade, deve ser maior que 0."
      return;
    }

    var adicionando = entrega.quantidade;
    this._entregas_parcial.forEach(c => {
      adicionando += c.quantidade;
    });

    setTimeout(() => {
      if (!entrega.data_entrega)
        return;

      if (adicionando > this._produto_selecionado.quantidade) {
        this._msg = "A quantidade total ultrapassa a quantidade atribuida no produto."
        return;
      }

      this._params_truck.adicionado = adicionando;
      this._params_truck.restante = this._produto_selecionado.quantidade - adicionando;

      this._params_truck._restante = this._params_truck.restante.toFixed(2).replace('.', ',');
      this._params_truck._adicionado = this._params_truck.adicionado.toFixed(2).replace('.', ',');
      entrega._quantidade = entrega.quantidade.toFixed(2).replace('.', ',');

      if (this.ValidaData(entrega._data_entrega)) {
        this._entregas_parcial.push(entrega);
        this._on_save = true;
      }

      this._entrega_parcial = new EntregaParcialComprador();
    }, 50);


    this.Ordernar();
  }

  ValidarQuantidade() {
    if (this._entregas_parcial && this._entregas_parcial.length > 0) {
      this._entregas_parcial.forEach(c => {
        c._quantidade = c.quantidade.toFixed(2).replace('.', ',');
        c._data_entrega = UseFullService.formatDate(new Date(c.data_entrega));
        c._index = (c._index) ? c._index++ : 1;
        this._params_truck.adicionado += c.quantidade;
      });

      this._params_truck.restante = this._produto_selecionado.quantidade - this._params_truck.adicionado;
      this._params_truck._restante = this._params_truck.restante.toFixed(2).replace('.', ',');
      this._params_truck._adicionado = this._params_truck.adicionado.toFixed(2).replace('.', ',');
    }
    this._params_truck._total = this._produto_selecionado.quantidade.toFixed(2).replace('.', ',');
  }

  ValidaData(data): boolean {
    var result = true;
    var _data = new Date(UseFullService.formatDateUS(data));
    this._entregas_parcial.forEach(c => {
      if (new Date(UseFullService.formatDateUS(String(c._data_entrega))) > _data) {
        this._msg = "A data deve ser igual ou superior, a data já adicionada.";
        result = false;
      }
    });
    return result;
  }

  Remove(entrega: EntregaParcialComprador) {
    var count = this._entregas_parcial.length;
    for (let i = 0; i < count; i++) {
      if (entrega == this._entregas_parcial[i]) {
        this._params_truck.adicionado = (this._params_truck.adicionado - entrega.quantidade);
        this._params_truck.restante = (this._produto_selecionado.quantidade - this._params_truck.adicionado);
        this._params_truck._restante = this._params_truck.restante.toFixed(2).replace('.', ',');
        this._params_truck._adicionado = this._params_truck.adicionado.toFixed(2).replace('.', ',');
        this._entregas_parcial.splice(i, 1);
        this._on_save = true;
        continue;
      }
    }
    this.Ordernar();
  }

  Ordernar() {
    var i = 0;
    setTimeout(() => {
      this._entregas_parcial.forEach(c => {
        i++;
        c._index = i;
      });
    }, 100);
  }

  Proporcionar() {
    this._restante = 0;
    this._restante = 0;
    var count = this._entregas_parcial.length;
    for (let i = 0; i < count; i++) {
      /*
      if(entrega == this._entregas_parcial[i]){
        this._entregas_parcial.splice(i, 1);
        continue;
      }*/
    }
  }

  _on_save = false;
  OnSave() {
    if (this._on_save && this._entregas_parcial && this._entregas_parcial.length > 0) {
      $('#data-entraga-parcial-modal').modal("hide");
      this._entregas_emit.emit(this._entregas_parcial);
    }else if(this._entregas_parcial && this._entregas_parcial.length < 1){      
      this._msg = "Para salvar é preciso ter ao menos 1 previsão cadastrada.";
    }
  }

  SetEntregaParcialProduto() {

  }
}

export class ParamsTruck {
  constructor() {
    this.restante = 0;
    this.adicionado = 0;
    this.total = 0;
    this._restante = "0,00";
    this._adicionado = "0,00";
    this._total = "0,00";
  }
  public restante: number;
  public adicionado: number;
  public total: number;

  public _restante: string;
  public _adicionado: string;
  public _total: string;
}