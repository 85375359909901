import { Component, OnInit } from '@angular/core';
import { LoadPageService } from 'src/app/service/load-page.service';

@Component({
  selector: 'app-load-page',
  templateUrl: './load-page.component.html',
  styleUrls: ['./load-page.component.css']
})
export class LoadPageComponent implements OnInit {

  constructor(public loadPageService: LoadPageService) { }

  ngOnInit() {
    
  }

}
