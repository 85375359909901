<div class="row breadcrumb-config border-bottom ">
    <div class="col-lg-12">

        <ol class="breadcrumb">
            <!--
            <li class="breadcrumb-item">
                <a href="/home">Home</a>
            </li>
            <li class="breadcrumb-item active">
                <strong>User</strong>
            </li>
            -->
        </ol>
    </div>
</div>