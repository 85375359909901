import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { StatusSolicitacaoProdutoEnum } from './status-solicitacao-produto-enum';
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
import { Produto } from './produto';
import { InsumoDoFornecedor } from './produto-fornecedor';

export class SolicitacaoProduto {
    public id: number;
    public usuario_id: number;
    public especificacao: string;
    public marca: string;
    public detalhe: string;
    public item_id: number;
    public status: number;
    public data_criacao: Date;
    public data_resposta: Date;
    public log: string;    
    public _count_pesquisa: number;

    public _status_descricao: string;
    public _data_formatada: string;
    public _log: SolicitacaoProdutoLog[];

    public GetStatus(): string{
        return StatusSolicitacaoProdutoEnum[this.status];
    }
}

export class SolicitacaoProdutoLog{
    public especificacao: string;
    public marca: string;
    public detalhe: string;
    public data: string;
    public status: number;
    public item_id: number;
    public produto: InsumoDoFornecedor;
}