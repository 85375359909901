import { Injectable, EventEmitter } from '@angular/core';
import { UseFullService } from './usefull/usefull.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AlertConfirmService {

  public _confirm: boolean
  public _code: string
  public _title: string
  public _message: string
  public _message_input: string
  public _message_input_geral: string
  public _placeholder: string
  public _input: string
  public _error: string
  public _require: string
  _list = []

  public confirmEmitter$: EventEmitter<boolean>

  constructor() {
    this.confirmEmitter$ = new EventEmitter()
  }

  Set(title: string, message: string, code: string) {
    this._confirm = false;
    this._code = code;
    this._title = title;
    this._message = message;
  }

  UnsetObservers() {
    this.confirmEmitter$.observers = []
  }
  Reset() {
    this._confirm = false;
    this._code = ''
    this._title = ''
    this._message = ''
    this._message_input = ''
    this._message_input_geral = ''
    this._placeholder = ''
    this._input = ''
    this._require = ''
    this._list = []
  }

  Show(input = false) {
    if (!input) {
      this._list = []
    }
    $('#global-modal').modal('show')
  }

  ConfirmEmit(confirm: boolean): void {
    if (confirm) {
      if (!this.Verificar())
        return;
      this.confirmEmitter$.emit(confirm);
    }
    $('#global-modal').modal('hide');
    setTimeout(() => { this.Reset() }, 10)
  }

  Verificar(): boolean {
    switch (this._require) {
      case 'cnpj':
        if (!UseFullService.IsCNPJ(this._input)) {
          this._error = 'Digite um cnpj válido!'
          return false
        }
        break;
    }

    return true
  }
}
