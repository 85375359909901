import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-data-modal',
  templateUrl: './data-modal.component.html',
  styleUrls: ['./data-modal.component.css']
})
export class ImageModalComponent implements OnInit {

  @Input() _nome_arquivo: string;
  @Input() _src_arquivo: string;

  constructor() { }

  ngOnInit() {
  }

}
