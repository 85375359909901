<!-- Modal -->
<div class="modal fade" id="message-modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" [innerHTML]="_titulo"></h4>

                <div class="ibox-tools">
                    <div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span> 
                        </button>
                    </div>
                </div>

            </div>

            <div class="modal-body modal-confirm" style="padding: 1rem;" [innerHTML]="_descricao"></div>
        </div>
    </div>
</div>