import { ProdutoService } from '../../../service/suprimento/produto.service';
import { Produto, EspecificacaoProduto, NomeProduto } from '../../../domain/suprimento/produto';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { ProdutoUnidadeMedida } from 'src/app/domain/suprimento/produto-unidade-medida';
import { ProdutoFornecedor, InsumoDoFornecedor, OrigemProduto } from 'src/app/domain/suprimento/produto-fornecedor';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { Pagination } from 'src/app/domain/common/pagination';
import { ProdutoCategoriaNivel, ComponetesSelecao, TipoSelecao } from 'src/app/domain/suprimento/produto-categoria-nivel';
import { CategoriaNivelService } from 'src/app/service/suprimento/categoria-nivel.service';
import { SuprimentoService } from 'src/app/service/suprimento/suprimento-service';
import { Suprimento } from 'src/app/domain/suprimento/suprimento';
import { AlertConfirmService } from 'src/app/service/alert-confirm.service';
import { CategoriaVirtual } from 'src/app/domain/suprimento/categoria-virtual';
import { CategoriaVirtualService } from 'src/app/service/suprimento/categoria-virtual.service';
import { InsumoCTRL } from './insumo.ctrl';
import { VinculoNivelService } from 'src/app/service/suprimento/vinculo-nivel.service';
import { RelacaoProdutoNivel } from 'src/app/domain/suprimento/relacao-produto-nivel';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { RepositoryService } from 'src/app/service/repository.service';
import { promise } from 'protractor';
import { Directive } from "@angular/core";

declare var $: any;

@Directive()
export class InsumoComponentCtrl {

  public _input_nivel: ProdutoCategoriaNivel;

  _code = 'app-insumo';
  public _suprimento: Suprimento;
  public _categorias: ProdutoCategoria[];
  public _unidade_medida: ProdutoUnidadeMedida[];
  public _ha_produto: boolean;
  public _carregando: boolean;

  count_nivel = 0;
  public _categoria_nivel: boolean;
  public _niveis: Array<ProdutoCategoriaNivel>;

  public _buscar: string;
  public _categoria_id: number;
  public _vinculado: boolean;
  public _loading_save: boolean;

  public _total_produto: number;
  public _total_pesquisa: number;
  public _paginas: Pagination[];

  public temp_produtos: InsumoDoFornecedor[];
  public delete_produto: InsumoDoFornecedor;

  public temp_produto_category: InsumoDoFornecedor;
  public _categoria_virtual: CategoriaVirtual;
  public categoria_selecionada: ProdutoCategoria;

  public _enumComponetesSelecao = ComponetesSelecao;

  constructor(
    public repository: RepositoryService,
    public httpProduto: ProdutoService,
    public httpSuprimentos: SuprimentoService,
    public categoriaNivelService: CategoriaNivelService,
    public confirmService: AlertConfirmService,
    public categoriaVirtualService: CategoriaVirtualService,
    public insumoCTRL: InsumoCTRL,
    public vinculoNivelService: VinculoNivelService,
    public route: ActivatedRoute,
    public router: Router,
    public userLoggedService: UserLoggedInService) {
    this._carregando = false;
    this._ha_produto = false;
    this._buscar = '';
    this._categoria_id = 0;
    this._paginas = new Array<Pagination>();
    this._paginas.push(new Pagination());
    this._suprimento = new Suprimento();
    this._suprimento.insumos = new Array<InsumoDoFornecedor>();
    this._input_nivel = new ProdutoCategoriaNivel();
    this._categoria_virtual = new CategoriaVirtual();
  }

  ClickBond(data: ProdutoFornecedor) {
    setTimeout(() => {
      data.vinculado = !data.vinculado;
    }, 10);
  }

  SaveProductProvider(data: InsumoDoFornecedor) {
    if (!data.ProdutoFornecedor._status)
      return;
    data.ProdutoFornecedor._loading = true;
    data.ProdutoFornecedor.cross_docking = (data.ProdutoFornecedor.cross_docking) ? data.ProdutoFornecedor.cross_docking : 0
    this.httpProduto.SetProductProvider(data).subscribe(
      d => {
        if (!data.ProdutoFornecedor.id) {
          data.id = d.id
          data.ProdutoFornecedor = d.ProdutoFornecedor;
          this.organizarLista();
        }
      },
      e => { setTimeout(() => { data.ProdutoFornecedor._loading = false; }, 1000); },
      () => {
        data.ProdutoFornecedor._loading = false; data.ProdutoFornecedor._status = false;
      }
    );
  }

  ConfirmDeleteProductProvider(data: InsumoDoFornecedor) {
    this.delete_produto = data;
    this.confirmService._code = this._code;
    this.confirmService._title = "Exclusão de Vinculo Produto";
    this.confirmService._message = "Deseja excluir dados do produto: " + data.nome + "?";
    this.confirmService.Show();
  }

  DeleteProductProvider(confirm: boolean) {
    if (!this.delete_produto || !confirm || this.confirmService._code != this._code)
      return;
    this.httpSuprimentos.Delete(this.delete_produto.ProdutoFornecedor.id).subscribe(
      d => { }, e => { },
      () => {
        this._suprimento.insumos.splice(this.delete_produto._index - 1, 1)
        this.organizarLista();
      }
    );
  }

  OnChangeAll(data: ProdutoFornecedor) {
    setTimeout(() => {
      data._status = (data.unidade_id >= 1 && data.preco && data.estoque) ? true : false;
    }, 10);
  }

  ultima_busca = "";
  OnChangeSearch(is_search: boolean = true) {

    setTimeout(() => {
      this.SetCategoriaSelecionada();
    }, 1);

    if (is_search) {

      this.insumoCTRL.LimparNiveisApartir(this._niveis, this._niveis[0]);

      if (this._niveis && this._niveis.length > 0) {
        this._niveis.forEach(c => { c._selected = undefined; });
      }

      setTimeout(() => {
        if (this.ultima_busca != this._buscar) {
          this.SetListaProdutos();
          this.ultima_busca = this._buscar;
        }
      }, 1000);
    } else {
      setTimeout(() => {
        this._categoria_virtual = new CategoriaVirtual();
        this._categoria_nivel = false;
        this._niveis = [];
        this.SetListaProdutos();
      }, 50);
    }
  }

  SetCategoriaSelecionada() {
    var primeiro_nome = this._buscar.split(" ")[0].toLocaleUpperCase();
    var categoria_id = this._categoria_id;
    function filtrar(obj) {
      return (("descricao" in obj && obj.descricao == primeiro_nome) || ("id" in obj && obj.id == categoria_id));
    }
    function filtrarOrigem(obj) {
      return ("descricao" in obj && obj.descricao == $(".select-category option:selected").text());
    }
    var categoria = this._categorias.filter(filtrar);
    if (categoria.length > 1)
      categoria = categoria.filter(filtrarOrigem);
    this.categoria_selecionada = categoria[0];

    if (!this.categoria_selecionada && !this._categoria_virtual) {
      this._categoria_virtual = new CategoriaVirtual();
    }
  }

  SearchBond(bond: boolean) {
    if (this._vinculado == bond)
      return;
    this._vinculado = bond;
    this.SetListaProdutos();
  }

  SetListaCatetorias(getList = true) {
    this.httpProduto.getAllCategories().subscribe(
      d => { this._categorias = d; },
      error => { },
      () => { if (getList) { this.SetListaProdutos(); } });
  }

  SetListaUnidadeMedida() {
    this.httpProduto.GetAllUnitsMeasured().subscribe(
      data => { this._unidade_medida = data; },
      error => { },
      () => { }
    );
  }

  SeachListaPorStatus(status) { this.SetListaProdutos(1, 0, false, "", status) }

  SetListaProdutos(page: number = 1, categoria_id: number = 0, origem_requisicao: any = false, niveis: string = "", status = 0) {
    this._carregando = true;
    this._ha_produto = false;
    var categoria_virtual_id = 0;

    if (categoria_id < 1) {
      categoria_id = this._categoria_id;
      if (this.categoria_selecionada && this._categoria_virtual && this._categoria_virtual._selecionado > 0) {
        categoria_id = this._categoria_virtual._selecionado;
      } else if (this.categoria_selecionada && this.categoria_selecionada.origem > 0) {
        categoria_id = 0;
      }
    }
    if (this.categoria_selecionada && this.categoria_selecionada.origem > 0)
      categoria_virtual_id = this.categoria_selecionada.id;

    this.httpSuprimentos.getAll(this._buscar, categoria_id, this._vinculado, page, false, categoria_virtual_id, niveis, status).subscribe(
      data => {
        data.insumos.forEach(c => {
          if (!c.ProdutoFornecedor)
            c.ProdutoFornecedor = new ProdutoFornecedor();
        });
        this._suprimento = data;
        this.SetConfigProduto(categoria_id, origem_requisicao);
      },
      error => { },
      () => {
        this.SetFunctions();
        this.CountProdutosPesquisa(this._buscar, categoria_id, this._vinculado, page, categoria_virtual_id, status);
      }
    );
  }

  SetConfigProduto(categoria_id, origem_requisicao) {
    if (this._buscar || this._categoria_nivel || this._categoria_id > 0 || (this._categoria_virtual && this._categoria_virtual._selecionado > 0)) {
      if (!origem_requisicao)
        this.VerificarBusca(categoria_id);
    }
    this.organizarLista();
  }

  organizarLista() {
    if (!this._vinculado)
      this.multipliqueData()
    let x = 1
    let xv = 1
    this._suprimento.insumos.forEach(e => {
      if (e.categoria_id > 0) {
        e._index = x++
        if (!e.ha_niveis || e.ha_niveis < 1)
          e._virtual_index = xv++
        e._categoria = ProdutoCategoria.GetName(this._categorias, e.categoria_id);
        e.ProdutoFornecedor.preco = (e.ProdutoFornecedor.preco > 0) ? e.ProdutoFornecedor.preco : null;
        e.ProdutoFornecedor.estoque = (e.ProdutoFornecedor.estoque > 0) ? e.ProdutoFornecedor.estoque : null;
        e.ProdutoFornecedor.cross_docking = (e.ProdutoFornecedor.cross_docking > 0) ? e.ProdutoFornecedor.cross_docking : null;
        if (e.ProdutoFornecedor == null || e.ProdutoFornecedor == undefined)
          e.ProdutoFornecedor = new ProdutoFornecedor();
      }
    });
  }

  multipliqueData() {
    let tempIdProduto = 0
    for (let i = 0; i < this._suprimento.insumos.length; i++) {
      const e = this._suprimento.insumos[i];
      if (e.ha_niveis < 1 && e.ProdutoFornecedor.id > 0 && e.id != tempIdProduto) {
        const haRow = this._suprimento.insumos.filter(o => { return (o.id === e.id && !o.ProdutoFornecedor.id) })
        tempIdProduto = e.id
        if (!haRow || haRow.length < 1)
          this._suprimento.insumos.splice(i, 0, this.newItem(e))
      }
    }
  }

  newItem(model): InsumoDoFornecedor {
    const data = new InsumoDoFornecedor();
    data.categoria_id = model.categoria_id
    data.ha_niveis = model.ha_niveis
    data.id = model.id
    data.nome = model.nome
    data.origem = model.origem
    data.ProdutoFornecedor = new ProdutoFornecedor();
    data.ProdutoFornecedor.produto_id = model.id
    return data;
  }

  CountProdutosPesquisa(buscar: string, categoria_id: number, vinculado: boolean, page, categoria_virtual_id, status = 0) {
    this.httpSuprimentos.getCountAll(this._buscar, categoria_id, this._vinculado, page, false, categoria_virtual_id, status).subscribe(
      data => {
        this._total_pesquisa = data;
      },
      error => { },
      () => {
        this._total_produto = this._total_pesquisa;
        var count_page = UseFullService.CalcularPaginacao(50, this._total_pesquisa);
        this._paginas = Pagination.SetPage(page, count_page);
      }
    );
  }

  GetPage(page: number): void {
    this.SetListaProdutos(page);
  }

  SetFunctions() {
    if (this.temp_produtos && this.temp_produtos.length > 0) {
      var produtos = InsumoDoFornecedor.RemoverJaAdicionado(this._suprimento.insumos, this.temp_produtos);

      produtos.forEach(c => {
        this._suprimento.insumos.push(c);
      });
      setTimeout(() => {
        this.temp_produtos = null;
      }, 50);
      this.organizarLista();
    }

    this._ha_produto = this._suprimento.insumos.length < 1;
    if (this._suprimento.insumos.length == 1)
      this._ha_produto = (this._suprimento.insumos[0].ha_niveis > 0);
    this._carregando = false;
    UseFullService.SetMask();
  }

  SelectCores(nivel: ProdutoCategoriaNivel) {
    this._input_nivel = nivel;
    this._input_nivel._multiple = (nivel.multiplo == TipoSelecao.Multipla);
    $("#pesquisa-cor-modal").modal('show');
  }

  InsertTempProduto(nome: string, categoria_id: number, categoria_nome: string, niveis: string) {
    var produto = new InsumoDoFornecedor();
    produto.nome = nome;
    produto._categoria = categoria_nome;
    produto.categoria_id = categoria_id;
    produto.origem = OrigemProduto.InsumoDoFonecedor;
    produto._niveis = niveis;
    produto.ha_niveis = 0;
    if (!this.temp_produtos)
      this.temp_produtos = new Array<InsumoDoFornecedor>();
    this.temp_produtos.push(produto);
  }

  VerificarBusca(categoria_id) {
    this.httpSuprimentos.GetInsumoNivel(this._buscar, categoria_id).subscribe(
      d => { this.temp_produto_category = d; },
      e => { },
      () => {
        if (this.temp_produto_category && this.temp_produto_category.id > 0 && !this.categoria_selecionada)
          this.categoria_selecionada = ProdutoCategoria.FiltrarPorIdOrigem(this._categorias, this.temp_produto_category.categoria_id, 0);
        this.VerificarCategoriaBusca();
      }
    );
  }

  OnChangeSearchCategoria() {
    this._buscar = "";
    this._categoria_nivel = false;
    this._niveis = [];
    setTimeout(() => {
      this.SetListaProdutos(1, this._categoria_virtual._selecionado);
    }, 10);
  }

  VerificarCategoriaBusca() {
    if (this.categoria_selecionada) {
      var temp_categoria_id = (this._categoria_id > 0) ? this._categoria_id : this.categoria_selecionada.id;
      if (this.categoria_selecionada && (this.categoria_selecionada.pai_id > 0 || this.categoria_selecionada.origem > 0)) {
        var id_categoria_selecionada = this._categoria_virtual._selecionado;
        var nome = (this.categoria_selecionada.origem) ? this.categoria_selecionada.descricao : this.categoria_selecionada.pai_nome;
        this.categoriaVirtualService.GetName(nome).subscribe(
          d => { this._categoria_virtual = d; },
          e => { },
          () => {

            if (id_categoria_selecionada > 0) {
              this._categoria_virtual._selecionado = id_categoria_selecionada;
              this.SetNiveisPorCategoria(id_categoria_selecionada);
            } else if (this.temp_produto_category && this._buscar) {
              this._categoria_virtual.categorias.forEach(c => {
                if (c.id == this.temp_produto_category.categoria_id) {
                  this._categoria_virtual._selecionado = this.temp_produto_category.categoria_id;
                  this.SetNiveisPorCategoria(this.temp_produto_category.categoria_id);
                }
              });
            }
          }
        );

      } else {
        this._categoria_virtual = new CategoriaVirtual();
        this.SetNiveisPorCategoria(temp_categoria_id);
      }


    } else {
      this._categoria_nivel = false;
      this._niveis = [];
      this._categoria_virtual = new CategoriaVirtual();
    }
  }

  SetNiveisPorCategoria(temp_categoria_id: number) {
    if (this._niveis && this._niveis.length > 0 && this._niveis[0].categoria_id == temp_categoria_id)
      return;
    this.categoriaNivelService.GetAll(temp_categoria_id).subscribe(
      d => { this.ExibirNiveis(d); },
      c => { },
      () => { }
    );
  }

  ExibirNiveis(categoriasNiveis: Array<ProdutoCategoriaNivel>) {
    this._categoria_nivel = categoriasNiveis.length > 0;
    this._niveis = categoriasNiveis;
    this._niveis.forEach(c => { c._ativo = false; });
    if (this._niveis && this._niveis.length > 0) {
      this._niveis[0].nivel.forEach(e => { e._temp_id_vinculo = 1; });
      this._niveis[0]._ativo = true;
    }
  }

  ReciverCoresSelecionadas(nivel: ProdutoCategoriaNivel) {
    this._input_nivel._selected = 0;
    nivel.nivel.forEach(c => {
      if (c._selecionado)
        this._input_nivel._selected = c.id;
    });
    this.OnChangeSearchNivel(nivel);
  }

  /************* Eventos Change *******************/
  OnChangeSearchNivel(nivel: ProdutoCategoriaNivel) {

    this.insumoCTRL.LimparNiveisApartir(this._niveis, nivel);

    if (isNaN(nivel._selected))
      return;

    this.SelectItemNivel(nivel);

    if (!this.temp_produto_category)
      this.temp_produto_category = InsumoDoFornecedor.FiltrarHaNivel(this._suprimento.insumos);

    var nomes = [this.temp_produto_category.nome];
    var niveis = [""];
    var relacoesNiveis = new Array<RelacaoProdutoNivel>();
    this._niveis.forEach(c => {

      //Adicionar descrição de nivel de componentes modal
      if (c.selecao == ComponetesSelecao.modal && c._selected) {
        var count_nomes = nomes.length;
        for (let i = 0; i < count_nomes; i++) {
          var primeiro = true;
          var temp_nome = "";
          var temp_nivel = "";
          c.nivel.forEach(cn => {
            if (cn._selecionado) {
              if (primeiro) {
                temp_nome = nomes[i];
                temp_nivel = niveis[i];
                nomes[i] += " " + c.pre_fixo + cn.descricao;
                niveis[i] += c.nivel_id + "," + cn.id + "," + c.id + ":";
                primeiro = false;
              } else {
                niveis.push(temp_nivel + c.nivel_id + "," + cn.id + "," + c.id + ":");
                nomes.push(temp_nome + " " + c.pre_fixo + cn.descricao);
              }
              relacoesNiveis.push(RelacaoProdutoNivel.NovaRelacaoProdutoNivel(c.nivel_id, cn.id, c.id));
            }
          });
          if ((i + 1) == count_nomes) {
            if (this._niveis[this._niveis.length - 1].id == c.id) {
              this.SetTempProduto(nomes, niveis);
            }
          }
        }
        //Adiciona descrição de nivel de componentes select
      } else if (c._selected != undefined && c._selected > 0) {
        var subcategoria = EspecificacaoProduto.FiltrarPorId(c.nivel, c._selected);
        for (let i = 0; i < nomes.length; i++) {
          nomes[i] += " " + c.pre_fixo + subcategoria.descricao;
          niveis[i] += c.nivel_id + "," + subcategoria.id + "," + c.id + ":";
          relacoesNiveis.push(RelacaoProdutoNivel.NovaRelacaoProdutoNivel(c.nivel_id, c._selected, c.id));
        }
        if (this._niveis[this._niveis.length - 1].id == c.id) {
          this.SetTempProduto(nomes, niveis);
        }

      } else
        this.temp_produtos = null;;
    });
    var params = "";
    relacoesNiveis.forEach(e => {
      params += e.nivel_id + "," + e.nivel_item_id + "," + e.nivel_virtual_id + ":";
    });
    this._buscar = this.temp_produto_category.nome;
    this.SetListaProdutos(1, 0, true, params);
  }

  async SetTempProduto(nomes: Array<string>, niveis: Array<string>) {
    var _this = this
    for (let i = 0; i < nomes.length; i++) {
      const e = nomes[i];
      await new Promise(function (resolve, reject) {
        _this.InsertTempProduto(e, _this.temp_produto_category.categoria_id, _this.temp_produto_category.nome, niveis[i]);
        resolve(true)
      });
    }
  }

  /************** Get Data *************** */
  SelectItemNivel(nivel: ProdutoCategoriaNivel) {
    var nivel_filho = ProdutoCategoriaNivel.ObterPorOrdem(this._niveis, nivel.order + 1);
    var niveis = "";
    var count = nivel.nivel.length;
    var i = 0;
    nivel.nivel.forEach(c => {

      i++;
      if (c._selecionado) {
        niveis += c.id + ",";
      }
      if (i == count)
        BuscarSubNiveis(this);
    });

    function BuscarSubNiveis(_this) {
      if (nivel_filho) {
        nivel_filho._ativo = true;
        nivel_filho.nivel.forEach(e => { e._temp_id_vinculo = 0; });
        _this.vinculoNivelService.GetAllNivel(nivel.id, nivel._selected, niveis.substring(0, (niveis.length - 1))).subscribe(
          (data) => {
            data.forEach(e => {
              nivel_filho.nivel.forEach(c => {
                if (e.filho_nivel_item_id == c.id) {
                  c._temp_id_vinculo = e.id;
                }
              });
            });
          }
        );
      }
    }
  }
}