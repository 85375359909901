import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

declare var $: any
@Component({
  selector: 'app-alert-confirm-secundario',
  templateUrl: './alert-confirm-secundario.component.html',
  styleUrls: ['./alert-confirm-secundario.component.css']
})
export class AlertConfirmSecundarioComponent implements OnInit {

  constructor() { }
  
  @Input() _titulo_confirm: string
  @Input() _descricao_confirm: string
  @Input() _id_model_pai: string
  @Output() SendConfirm: EventEmitter<boolean> = new EventEmitter()

  ngOnInit() {
    $('#global-confirm-secundario-modal').on('show.bs.modal', function (e) {

    })
  }

  ConfirmEmit(confirm: boolean) {
    if (confirm)
      this.SendConfirm.emit(confirm)

      this.hide()
  }

  hide() {
    $("#global-confirm-secundario-modal").removeClass('show')
    $("#global-confirm-secundario-modal").hide()
    $("#global-confirm-secundario-modal").modal('hide')
    $('#'+this._id_model_pai).removeClass('modal-z-index')
  }

}
