import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Produto } from 'src/app/domain/suprimento/produto';
import { ProdutoCategoria } from 'src/app/domain/suprimento/produto-categoria';
import { ProdutoService } from 'src/app/service/suprimento/produto.service';
import { ProdutoFornecedor, InsumoDoFornecedor } from 'src/app/domain/suprimento/produto-fornecedor';
import { IfStmt } from '@angular/compiler';
import { Pagination } from 'src/app/domain/common/pagination';
import { UseFullService } from 'src/app/service/usefull/usefull.service';
import { SuprimentoService } from 'src/app/service/suprimento/suprimento-service';
import { Suprimento } from 'src/app/domain/suprimento/suprimento';

declare var $: any;
@Component({
  selector: 'app-pesquisa',
  templateUrl: './pesquisa.component.html',
  styleUrls: ['./pesquisa.component.css']
})
export class PesquisaComponent implements OnInit {

  @Output() resposta_produto_selecionado: EventEmitter<InsumoDoFornecedor> = new EventEmitter();

  public _suprimento: Suprimento;
  public _produto_selecionado: InsumoDoFornecedor;
  public _categorias: ProdutoCategoria[];
  public _ha_produto: boolean;
  public _carregando: boolean;

  public _save_active: boolean;

  public _buscar: string;
  public _categoria_id: number;
  public _vinculado: boolean;

  public _count_grid: number;
  public _total_pesquisa: number;
  public _paginas: Pagination[];

  constructor(private httpProduto: ProdutoService,
    private httpSuprimentos: SuprimentoService) {
    this._carregando = false;
    this._ha_produto = false;
    this._buscar = '';
    this._categoria_id = 0;
    this._suprimento = new Suprimento();
  }

  ngOnInit() {
    this.SetListaCatetorias();
    this.SetListaProdutos();
    this.init();
  }

  SelectItem() {
    this.resposta_produto_selecionado.emit(this._produto_selecionado);
  }

  ClickBond(produto: InsumoDoFornecedor) {
    this._save_active = false;
    this._suprimento.insumos.forEach((c, e, r) => {
      if (produto.id != c.id)
        c._selecionado = false;
      else {
        produto._selecionado = !produto._selecionado;
        this._save_active = produto._selecionado;
        if (produto._selecionado)
          this._produto_selecionado = produto;
      }
    });
  }

  OnChangeAll(data: ProdutoFornecedor) {
    data._status = true;
  }

  OnChangeSearch() {
    setTimeout(() => {
      this.SetListaProdutos();
    }, 100);
  }

  SetListaCatetorias() {
    this.httpProduto.getAllCategories().subscribe(
      data => { this._categorias = data; },
      error => { },
      () => { }
    );
  }

  SetListaProdutos(page: number = 1) {
    this._carregando = true;
    this._ha_produto = false;
    this.httpSuprimentos.getAll(this._buscar, this._categoria_id, this._vinculado, page, true).subscribe(
      data => {
        this._suprimento = data;
        this.SetConfigProduto(page);
      },
      error => { },
      () => {
        this.CountProdutosPesquisa(this._buscar, this._categoria_id, this._vinculado, page, true) 
        this.SetFunctions(page);
      }
    );
  }

  CountProdutosPesquisa(buscar: string, categoria_id: number, vinculado: boolean, page, categoria_virtual_id) {
    this.httpSuprimentos.getCountAll(this._buscar, categoria_id, this._vinculado, page, false, categoria_virtual_id).subscribe(
      data => {
        this._total_pesquisa = data;
      },
      error => { },
      () => {
        var count_page = UseFullService.CalcularPaginacao(50, this._total_pesquisa);
        this._paginas = Pagination.SetPage(page, count_page);
      }
    );
  }

  SetConfigProduto(page: number) {
    setTimeout(() => {
      this._suprimento.insumos.forEach(c => { c._categoria = ProdutoCategoria.GetName(this._categorias, c.categoria_id); });
    }, 2000);
  }

  GetPage(page: number): void {
    this.SetListaProdutos(page);
  }

  SetFunctions(page: number) {
    this._ha_produto = this._suprimento.insumos.length < 1;
    this._carregando = false;
    this.SetConfigProduto(page);
  }

  Show(): void {
    $('#pesquisa-insumo-modal').modal('show');
  }

  close(): void {
    $('#pesquisa-insumo-modal').modal('hide');
  }

  init(): void {
    $('#pesquisa-insumo-modal').on('show.bs.modal', function (e) {
      $(".modal-max").css("max-height", ($("body").height() - 220) + "px");
    });
  }
}