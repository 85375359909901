<div class="col-lg-12 wrapper-top-content">
    <div class="ibox">
        <div class="ibox-title">
            <h5>Cadastro de Cotação</h5>
        </div>
        <div class="ibox-content">

            <div class="row">
                <div class="col-sm-3">
                    <b>Número: </b><label>&nbsp;&nbsp;{{_cotacao.codigo}}</label>
                </div>
                <div class="col-sm-3">
                    <b>Nome: </b><label>&nbsp;&nbsp;{{_cotacao.nome}}</label>
                </div>
                <div class="col-sm-3">
                    <b>Obra: </b><label>&nbsp;&nbsp;{{_cotacao.nome_obra}}</label>
                </div>

                <!--
                <div class="col-sm-4 text-right">
                    <button class="btn btn-primary" (click)="AdicionarInsumo();"><i class="fa fa-plus"></i>&nbsp;&nbsp;Adicionar Insumo</button></div>
                -->
            </div>

            <div class="table-responsive min-height-grid-insumo margin-top-15">
                <table class="table table-hover table-sm">
                    <thead class="thead-dark thead-fixed">
                        <tr>
                            <th rowspan="2" class="th-sequece-produto">Item</th>
                            <th rowspan="2" class="th-nome-produto">Nome do Insumo
                            </th>
                            <th rowspan="2" class="text-center th-categoria">Marca</th>
                            <th rowspan="2" class="text-center
                                th-select-unitycolumn-unidade">Unidade</th>
                            <th rowspan="2" class="text-center th-input-stock">Quantidade</th>
                            <th rowspan="2" class="text-center th-action-anexo">Anexo</th>
                            <th rowspan="2" class="text-center
                                th-action-parcial" *ngIf="(_cotacao.recebimento_produto == 2)">Recebimento Parcial
                            </th>

                            <th class="text-center width-120-px" rowspan="2">Qte. mês
                                <br />acum.</th>
                            <th class="text-center width-120-px" colspan="2">Qte. Obra
                            </th>

                            <th rowspan="2" class="text-center" colspan="1">#</th>
                        </tr>
                        <tr>
                            <th class="text-center width-60-px">Acum.</th>
                            <th class="text-center width-60-px">Total</th>
                        </tr>
                    </thead>
                    <tbody class="" [hidden]="_carregando" *ngFor="let produto
                        of _list_produtos | filter : _pesquisar">
                        <tr *ngIf="produto.id> 0" (mouseenter)="mouseEnter(produto)" (mouseleave)="mouseLeave(produto)">
                            <td class="width-120-px" style="padding-left:
                                10px;">
                                {{produto._index}}
                                <i class="fa fa-plus cursor-pointer btn-link
                                    margin-right-10" *ngIf="produto._selected_hover &&
                                    !produto._toSon" (click)="addRow(produto)" style="float: right;"></i>
                                <i class="fa fa-minus cursor-pointer btn-link
                                    margin-right-10" *ngIf="produto._selected_hover &&
                                    produto._toSon" (click)="removeRow(produto)" style="float:
                                    right;"></i>
                            </td>
                            <td class="td-nome-produto">{{produto.nome}}</td>
                            <td class="text-center">
                                <span *ngIf="produto?._marcas">{{produto._marcas.length}}</span> marca(s) &nbsp;
                                <button class="btn btn-default btn-sm" (click)="showModalMarcas(produto)">&nbsp;+&nbsp;</button>
                            </td>

                            <td class="text-left th-select-unity">
                                <select class="form-control-sm form-control
                                    cursor-pointer
                                    text-center input-s-sm inline select-unity" (ngModelChange)="OnChangeAll(produto)" [(ngModel)]="produto.unidade_id">
                                    <option value="0" disabled
                                        style="color:#ccc;">Unidade</option>
                                    <option *ngFor="let unidade of
                                        _unidade_medida" value="{{unidade.id}}">{{unidade.nome}}</option>
                                </select>
                            </td>
                            <td class="text-center th-input-stock">
                                <input class="form-control input-stock" data-toggle="tooltip" data-placement="top" title="Campo quantidade
                                    deve ter apenas
                                    números
                                    e virgula." type="number" pattern="[0-9]+$" (ngModelChange)="OnChangeAll(produto)" [(ngModel)]="produto.quantidade" placeholder="0">
                            </td>
                            <td class="text-center th-action-anexo">
                                <button class="fileUpload btn btn-sm-grid
                                    btn-link">
                                    <i class="fa fa-paperclip"></i>
                                    <input #imageInput type="file" name="logo"
                                        id="fileImage" class="upload" accept="*"
                                        placeholder="Inserir Logo"
                                        (change)="CarregarFile(produto,
                                        imageInput)">
                                </button>
                                <label *ngIf="produto.anexo && produto.anexo !=
                                    'null'" class="btn-link cursor-pointer
                                    label-anexo" (click)="MostrarFile(produto)">
                                    <small>{{produto.anexo}}</small>
                                </label>

                                <i *ngIf="produto.anexo && produto.anexo !=
                                    'null'" (click)="ConfirmRemoveImg(produto)" class="fa fa-times cursor-pointer btn-link"></i>
                            </td>
                            <td class="text-center th-action-parcial" *ngIf="(_cotacao.recebimento_produto == 2)">
                                <button class="btn btn-sm-grid btn-link">
                                    <i class="fa fa-truck"
                                        (click)="OpenModalTrunck(produto)"></i>
                                </button>
                                <small class="btn-link cursor-pointer"></small>
                            </td>

                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{produto._produto_obra.previsto_total}}</td>
                            <!--
                            <td class="text-center th-action-salvar">
                                <button class="btn btn-sm-grid btn-primary
                                    {{produto._status ? '':
                                    'disabled' }}" (click)="SaveProduct(produto)">
                                    <span class=""
                                        [hidden]="produto._loading">Salvar</span>
                                    <div class="spinner-border"
                                        role="status"
                                        [hidden]="!produto._loading">
                                        <span class="sr-only"></span>
                                    </div>
                                </button>
                            </td>
                            -->
                            <td class="text-center th-action-delete">
                                <button class="btn btn-sm-grid btn-link" [disabled]="load" (click)="ConfirmDeleteProduct(produto)">
                                    <i class="fa fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="(!(produto.id) || produto.id <1)">
                            <td class="text-center width-120-px td-icon
                                align-top" style="padding-top: 8px;"></td>
                            <td class="td-nome-produto" colspan="8">
                                <button type="submit" style="float: right;" *ngIf="_cotacao.obra_id > 0 && _buscar_produto" class="btn btn-primary margin-left-10" (click)="adicionarNovoProduto()"><i class="fa fa-plus"></i>&nbsp;&nbsp;Novo Produto</button>
                                <input type="text" style="width: calc(100% - 150px);" placeholder="Digite o nome do insumo para buscar" id="_search_" [(ngModel)]="_buscar_produto" class="form-control input-td" />
                                <div style="margin: 0 auto; max-width: calc(100%)" *ngIf="_cotacao.obra_id > 0 && _buscar_produto">
                                    <app-grid-produto-obra-component [_buscar_produto]="_buscar_produto" [_obra_id]="_cotacao.obra_id" (_produtos_adicionados)="ReceiverProdutos($event)"></app-grid-produto-obra-component>
                                </div>
                            </td>
                            <td colspan="2"></td>
                        </tr>
                    </tbody>
                </table>

                <div *ngIf="_list_produtos.length <1" class="mensagem-produto">
                    <label class="btn-link-text" (click)="AdicionarInsumo();"><b>+
                            Adicione Insumo </b></label> para continuar.<br /><br />
                    <b>Dicas para receber os melhores orçamentos:</b><br /> 1 - Quanto maior a quantidade do produto, maior poder de negociação;
                    <br /> 2 - Faça a cotação com produtos da mesma categoria;
                    <br /> 3 - Separe um periodo razoável (mínimo 2 dias) para receber orçamentos.
                </div>

                <div [hidden]="!_carregando" class="carregando-produtos">
                    <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                    </div>
                    carregando...
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6 text-right">
                    <button type="submit" class="btn btn-default Right" (click)="Reload()"><i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Voltar</button>
                    <button type="submit" class="btn btn-primary margin-left-10" (click)="Right()"><i class="fa fa-check"></i>&nbsp;&nbsp;Continuar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>
<app-entrega-parcial-comprador [_produto_selecionado]="_produto_selecionado" (_entregas_emit)="ReceiverEntregaProduto($event)"></app-entrega-parcial-comprador>
<app-modal-multiselect [_input]="_input" [_multiple]="true" (_return)="receiverData($event)"></app-modal-multiselect>