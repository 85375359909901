import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authenticate/authentication.service';
import { Router } from '@angular/router';
import { UserLoggedInService } from 'src/app/service/authenticate/user-logged-in.service';
import { TypeUser } from 'src/app/domain/usuario/Usuario';

@Component({
  selector: 'app-configuracao-admin',
  templateUrl: './configuracao-admin.component.html',
  styleUrls: ['./configuracao-admin.component.css']
})
export class ConfiguracaoAdminComponent implements OnInit {


  public _listMenu: Array<object>;
  public _menu_nav_bar: Array<MenuNavBar>;

  constructor(private httpAuthenticate: AuthenticationService, private router: Router, public userLoggedService: UserLoggedInService) { }

  ngOnInit() {
    this.userLoggedService.SetCurrentUser();
    this.SetMenu();

  }

  SetMenu() {
    this._listMenu = [
      { text: 'Dados Empresa', route: '/configuracao', access: [TypeUser.administrator, TypeUser.provider] },
      { text: 'Niveis de Categoria', route: '/configuracao-adm/admin/categoria-nivel', access: [TypeUser.administrator] },
      { text: 'Vincular Niveis', route: '/configuracao-adm/admin/vincula-niveis', access: [TypeUser.administrator] },
      { text: 'Categorias Virtual', route: '/configuracao-adm/admin/categoria-virtual', access: [TypeUser.administrator] },
      { text: 'Funções do Sistema', route: '/configuracao-adm/admin/funcao-sistema', access: [TypeUser.administrator] },
      { text: 'Perfil Usuário', route: '/configuracao-adm/admin/perfil-usuario', access: [TypeUser.administrator] },
      { text: 'Cadastro Prazo', route: '/configuracao-adm/admin/prazo-pagamento', access: [TypeUser.administrator] }
    ];

    setTimeout(() => {
      this.CarregarMenu();
    }, 100);
  }

  
  public CarregarMenu() {
    this._menu_nav_bar = new Array<MenuNavBar>();
    this._listMenu.forEach(c => {
      var menu = c as MenuNavBar;
      var autorizado = false;
      menu.access.forEach(m => {
        if (this.userLoggedService.type === m)
          autorizado = true;
      });
      if (autorizado)
        this._menu_nav_bar.push(menu);
    });
  }
}

export class MenuNavBar {
  public text: string;
  public route: string;
  public access: [];
  public _active: boolean;
}
