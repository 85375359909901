<div class="col-lg-12 wrapper-top-content" *ngIf="(_compra_venda &&
    _compra_venda.id> 0)">
    <div class="ibox">
        <div class="ibox-title" style="padding-right:20px;">
            <h5>Ordem de Venda:&nbsp;&nbsp; {{_orcamento.codigo}} </h5>
            <div class="text-primary" style="float: right;"><b class="text-dark">Status: </b>&nbsp;&nbsp; {{_compra_venda.status === 0 ? 'Em Análise' : (_compra_venda.status === 1) ? 'Aprovado':'Recusada' }} </div>
        </div>
        <div class="ibox-content">

            <app-cabecalho-orcamento></app-cabecalho-orcamento>

            <div class="table-responsive min-height-grid-insumo
                div-table-fixed margin-top-15" *ngIf="_carregamento_concluido">
                <!-- table-striped table-hover table-bordered -->
                <table class="table table-striped table-hover table-bordered" style="border: 1px solid #EBEBEB;">
                    <thead class="thead-fixed">
                        <tr>
                            <th class="th-sequece-produto" rowspan="2">Item</th>
                            <th class="th-nome-produto" rowspan="2">Nome do Insumo
                            </th>
                            <th class="text-center th-select-unity
                                column-unidade" rowspan="2">Marca</th>
                            <th class="text-center th-select-unity
                                    column-unidade" rowspan="2">Unidade</th>
                            <th class="text-center th-input-padrao-80" rowspan="2">Quantidade</th>
                            <th class="text-center th-select-unity" *ngIf="_ha_anexo" rowspan="2">Anexo</th>
                            <th class="text-center th-select-unity" rowspan="2" *ngIf="(_cotacao.recebimento_produto == 2)">Entrega Parcial
                            </th>
                            <th class="text-center" colspan="2">Preço R$</th>
                            <th class="text-center" colspan="3">Impostos R$</th>
                            <th class="text-center th-input-padrao-120" rowspan="2">Valor Total - R$</th>
                        </tr>
                        <tr>
                            <th class="text-center th-input-padrao-100">Registrado</th>
                            <th class="text-center th-input-padrao-100">Orçado</th>
                            <th class="text-center th-input-padrao-65">ICMS %</th>
                            <th class="text-center th-input-padrao-65">IPI %</th>
                            <th class="text-center th-input-padrao-65">Vlr.IPI</th>
                        </tr>
                    </thead>
                    <tbody class="" *ngFor="let produto of
                        _list_produtos">

                        <tr class="{{!produto._index_impar_par ?
                            'tr-background-impar':'tr-background-par'}}
                            {{(produto._orcamento_produto.estoque ||
                            !produto._orcamento_produto.id) ?
                            'tr-checked-stock-of':''}}">

                            <td class="text-center th-sequence-produto">{{produto._index}}</td>
                            <td class="td-nome-produto">{{produto.nome}}</td>
                            <td class="text-center th-input-stock">{{produto._orcamento_produto._marca}}</td>
                            <td class="text-center th-select-unity">{{produto._unidade_nome}}</td>
                            <td class="text-center th-input-stock">{{produto._quantidade}}</td>
                            <td class="text-center th-action-anexo" *ngIf="_ha_anexo">
                                <i *ngIf="produto.anexo && produto.anexo !=
                                    'null'" class="fa fa-file-alt btn-link
                                    cursor-pointer" (click)="MostrarFile(produto)"></i>
                            </td>

                            <td class="text-center th-action-parcial" *ngIf="(_cotacao.recebimento_produto == 2)">
                                <button class="btn btn-sm-grid btn-link">
                                    <i class="fa fa-truck"
                                        (click)="OpenModalTrunck(produto)"></i>
                                </button>
                                <small class="btn-link cursor-pointer"></small>
                            </td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_registrado}}
                            </td>
                            <td class="text-right th-input-price">
                                R$ {{produto._orcamento_produto._preco_string}}
                            </td>
                            <td class="text-center th-input-stock">
                                {{produto._orcamento_produto._icms_string}}
                            </td>
                            <td class="text-center th-input-stock">
                                {{produto._orcamento_produto._ipi_string}}
                            </td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_ipi}}
                            </td>
                            <td class="text-right th-input-stock">R$ {{produto._orcamento_produto._preco_total}}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="form-group row">
                <div class="col-sm-8 text-termo">
                </div>
                <div class="col-sm-4">
                    <table class="table table-striped table-bordered
                        table-aliquota" style="border: 0;">
                        <!--
                        <thead class="th-checked-tipo-frete" *ngIf="_cotacao.tipo_frete == 3">
                            <th colspan="2" style="padding-left: 0;border:0px;">Valor para entrega 
                                {{(_tipo_entrega == 'fob' && !_orcamento.fob)?'FOB':'CIF'}}</th>
                        </thead>
                    -->
                        <tbody class="">
                            <tr class="tr-background-impar">
                                <td class="text-left"><b>Subtotal</b></td>
                                <td class="th-input-padrao-120 text-right">R$ {{_orcamento._subtotal}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>

                            <tr *ngFor="let aliquota of _aliquotas" class="tr-background-impar">
                                <td>{{aliquota.descricao}}</td>
                                <td class="th-input-padrao-120 text-right">R$ {{aliquota._valor}}
                                </td>
                            </tr>
                            <tr class="tr-background-impar" *ngIf="_aliquotas.length> 0">
                                <td class="text-left" style="border:0;"></td>
                                <td class="th-input-padrao-120 text-right" style="border:0;"></td>
                            </tr>

                            <tr class="tr-background-par">
                                <td class="text-left"><b>VALOR TOTAL</b></td>
                                <td class="th-input-padrao-120 text-right"><b>R$
                                        {{_orcamento._total}}</b></td>
                            </tr>

                            <tr>
                                <td colspan="2" class="text-right
                                    no-padding" style="border:0px;
                                    padding-top: 15px
                                    !important">
                                    <div class="text-left" style="margin-bottom:
                                        10px;">
                                        <button class="btn btn-link" *ngIf="(_cotacao.tipo_frete == 2 || (_cotacao.tipo_frete == 3  && _orcamento.cif == 0)|| _orcamento.tipo_frete == 2)" (click)="carregarArquivoPropota(_orcamento.id, _orcamento.file_proposta_fob)">
                                            <b>Proposta:</b>&nbsp;&nbsp;{{_orcamento.file_proposta_fob}}
                                        </button>
                                        <button class="btn btn-link" *ngIf="(_cotacao.tipo_frete == 1 || (_cotacao.tipo_frete == 3 && _orcamento.cif == 0))" (click)="carregarArquivoPropota(_orcamento.id,
                                            _orcamento.file_proposta_cif)">
                                            <b>Proposta:</b>&nbsp;&nbsp;{{_orcamento.file_proposta_cif}}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-6">
                    <label *ngIf="_compra_venda.status === 0"><i
                            class="fa fa-clock"></i>&nbsp;&nbsp; Aguardando
                        Aprovação do Fornecedor</label>
                </div>
                <div class="col-sm-6 text-right">
                    <a class="btn btn-default" href="javascript:history.back()">
                        <i class="fa fa-arrow-left"></i>&nbsp;&nbsp;Voltar</a>
                </div>
            </div>

        </div>
    </div>
</div>

<app-data-modal [_nome_arquivo]="_nome_arquivo" [_src_arquivo]="_src_arquivo"></app-data-modal>
<app-entrega-parcial-view [_produto_selecionado]="_produto_selecionado" (_entregas_emit)="ReceiverEntregaProduto($event)"></app-entrega-parcial-view>