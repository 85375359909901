import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { SolicitacaoProduto } from 'src/app/domain/suprimento/solicitacao-produto';
import { GlobalVariable } from 'src/app/core/global-variable';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoService {
  
  private url = GlobalVariable.BASE_API_URL + "suppliesSolicitationProvider/";
  constructor(private http: HttpClient) { }

  GetAllUserLoggedIn(status: number, page: number = 1) {
    return this.http.get<SolicitacaoProduto[]>(this.url + "getalluserloggedin?status="+status+"&limite=100&page="+page).pipe(
      map(res => {
        return res;
      })
    );
  }

  GetAll(status: number, page: number = 1) {
    return this.http.get<SolicitacaoProduto[]>(this.url + "getall?status="+status+"&limite=100&page="+page).pipe(
      map(res => {
        return res;
      })
    );
  }

  Get(id: number) {
    return this.http.get<SolicitacaoProduto>(this.url + "get?id="+id).pipe(
      map(res => {
        return res;
      })
    );
  }
  
  SetSolicitationProvider(solicitacao: SolicitacaoProduto): Observable<any> {    
    if(solicitacao.id > 0)
      return this.Update(solicitacao);
    else
      return this.Create(solicitacao);
  }

  Create(solicitacao: SolicitacaoProduto): Observable<any>{
    return this.http.post<any[]>(this.url+"create", solicitacao).pipe(
      map(res=> { return res; })
    );
  }

  Update(solicitacao: SolicitacaoProduto): Observable<any>{
    return this.http.put<any[]>(this.url+"edit", solicitacao).pipe(
      map(res=> { return res; })
    );
  }
  
  SetDeleteSolicitacao(solicitacao: SolicitacaoProduto): Observable<any> {    
    return this.http.delete(this.url+"delete?id="+solicitacao.id).pipe(
      map(res=> { return res; })
    );
  }
  
  LoadingPage() {
    var nav = $('.grid-right-detalhes');

    $(window).scroll(function () {

      if ($("body").width() < 992)
        return;
      if ($(this).scrollTop() > $('.grid-right-detalhes > div').height() + 30)
        nav.css("margin-top", $(this).scrollTop() + "px");
      else
        nav.css("margin-top", "0px");
    });
  }
}
