import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LoadPageService {

  public _hidden: boolean;
  public _percentage: number;

  constructor() { }

  StartLoadPage() {
    this._hidden = true;
    this._percentage = 0;
    this.StartLoadProportional();
  }

  private StartLoadProportional() {
    setTimeout(() => {
      this._percentage++;

      if (this._percentage < 50)
        this.StartLoadProportional();
    }, 1);
  }

  private EndLoadProportional() {
    setTimeout(() => {
      this._percentage++;
      if (this._percentage < 100)
        this.EndLoadProportional();
      else {
        setTimeout(() => {
          this._hidden = false;
          this._percentage = 0;
        }, 1000);
      }
    }, 5);
  }

  EndLoadPage() {
    this.EndLoadProportional();
  }

}