import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-localidade',
  templateUrl: './localidade.component.html',
  styleUrls: ['./localidade.component.css']
})
export class LocalidadeComponent implements OnInit {


  @Output() _resposta_modal: EventEmitter<any> = new EventEmitter();
  @Input() _input_modal: any;

  public _buscar: string;

  constructor() { }

  ngOnInit() {
    var _this = this;
    $('#pesquisa-localidade-modal').on('show.bs.modal', function (e) {
        $(".modal-max").css("max-height", ($("body").height() - 220) + "px");
        _this._buscar = "";
    });
  }

  Selecionar(data: InputAny) {
    data._selecionado = !data._selecionado;
  }

  ConcluirSelecao() {
    this._resposta_modal.emit(this._input_modal);
    $("#pesquisa-cor-modal").removeClass('show');
    $("#pesquisa-cor-modal").hide();
    $("#pesquisa-cor-modal").modal('hide');
  }

  Cancelar() {
    $("#pesquisa-cor-modal").removeClass('show');
    $("#pesquisa-cor-modal").hide();
    $("#pesquisa-cor-modal").modal('hide');
    $("#pesquisa-insumo-modal").removeClass('modal-z-index');
  }
}

export class InputAny {
  public id: number;
  public Id: number;
  public Nome: string;
  public Uf: string;
  public _selecionado: boolean;
  public descricao: string;
  public pai_id: boolean;
  public objeto: number;
}

export enum EnumOjectInputAny{
  regiao = 1,
  estado = 2,
  cidade = 3,
  categoria = 4,
  forma_pagamento = 5,
  prazo_pagamento = 6
}